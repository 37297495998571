@use "../targetsmart_design/bootstrap-theme/src/scss/targetsmart.scss" as
  TargetSmart;

.amplify-tabs,
.amplify-loader {
  display: none;
}

[data-amplify-authenticator] {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-amplify-container] {
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .border;
  @extend .rounded;
  @extend .p-4;
  @extend .bg-gray-100;
  input {
    @extend .form-control;
  }
  button {
    @extend .btn;
    @extend .w-100;
  }
  .amplify-button--primary {
    @extend .btn-primary;
  }
}

.amplify-visually-hidden {
  @extend .sr-only;
}

.amplify-field {
  @extend .mb-2;
}

.amplify-field-group--horizontal {
  display: flex;
  .amplify-field-group__field-wrapper--horizontal {
    flex-grow: 1;
    input[name="password"] {
      border-radius: 4px 0 0 4px;
    }
  }
  .amplify-field-group__outer-end {
    @extend .input-group-append;
    button {
      background-color: #f9f9f9;
      @extend .input-group-text;
      @extend .border;
      border-radius: 0 4px 4px 0;
      @extend .align-items-start;
    }
  }
}

.leaflet-pane svg {
  width: auto;
}
.leaflet-tile {
  filter: brightness(0.95) url(#monochrome) !important;
}
